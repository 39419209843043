<template>
    <v-card class="my-5">
      <v-toolbar elevation="5" color="green darken-2" dark>
        <v-btn icon @click="back()" class="pl-2">
          <v-icon x-large color="white">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-toolbar-title class="row justify-center">
          <v-toolbar-title>Создать новую подборку</v-toolbar-title>
        </v-toolbar-title>
      </v-toolbar>
      <v-row class="mx-3 my-6">
        <v-col cols="12" class="col col-lg-6 col-md-6 sm-6">
          <v-text-field dense
                        outlined
                        rounded
                        clearable
                        v-model="item.title"
                        label="Название подборки"/>
        </v-col>
        <v-col cols="12" class="mt-1 col col-lg-6 col-md-6 sm-6">
          <v-slider v-model="item.priority"
                    min="1"
                    max="20"
                    thumb-color="blue"
                    thumb-label="always"
                    :thumb-size="24"
                    label="Порядок"
                    ticks="always"
                    color="blue"
                    track-color="primary"/>
        </v-col>
        <v-col cols="12" class="col col-lg-10 col-md-10 sm-10">
          <v-textarea dense
                      hide-details
                      outlined
                      rounded
                      rows="3"
                      v-model="item.description"
                      label="Описание"/>
        </v-col>
        <v-col cols="12" align-self="end" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-center">
          <v-btn color="primary" @click="create" rounded outlined elevation="3">
            <span>Сохранить</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>

export default {

  data() {
    return {
      defaultItem: {
        title: "",
        priority: 0,
        description: ""
      },
      item: {
        title: "",
        priority: 0,
        description: ""
      },

    }
  },
  methods: {
    create() {
      this.$store.dispatch("recommendations/create", this.item);
      this.item = this.defaultItem;
      this.$router.push({name: 'recommendations'});
    },
    back() {
      this.$router.push({name: 'recommendations'});
    }
  }
}
</script>
